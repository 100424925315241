import { Middleware } from '@nuxt/types';

const middleware : Middleware = (context) => {
  const state = context.app.$vsf.$magento.config.state;
  const userToken = state.getCustomerToken();
  if (!userToken || userToken === undefined) {
    context.redirect("/");
  }
};
export default middleware;
